import React from 'react';
import {Box,Stack} from '@mui/material'
import Header from "../LP/Header"
import Footer from '../LP/Footer';
import CustomersFooter from './CustomersFooter';


function Customer2() {

    return (
        <>
            <Header/>
            <Box className='section-content' margin="0 auto" fontSize={{xs:16, md:16}} pt={15} pb={15} pl={3} pr={3}>

                <Box component="h1" fontSize={30} textAlign="center" fontWeight="bold" color="#007bff" mb={10}>血の通うクラウドサービス/安心して採用もできるようになり従業員数も2倍に</Box>

                <Box p={{xs:"20px",md:"30px 100px"}} backgroundColor="#efefef" >

                    <Stack flexDirection={{xs:"column-reverse", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>
                        <Box>
                            <Box fontSize={{xs:16, md:18}} fontWeight="bold">株式会社Criacao</Box>
                            <Box mt={2}>業種：サービス業</Box>
                            <Box mt={1}>サービス：総合スポーツクラブCriacao(クリアソン)の運営・企業・大学等への教育事業及び人材支援・コンルティング事業・企業向け研修・ビジネスコンサルティング事業</Box>
                            <Box mt={1}>従業員数：31名</Box>
                        </Box>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/Customer2_Logo.png"} alt="Customer2_Logo" width="100%" maxWidth={300}></Box>
                    </Stack>

                    <Stack flexDirection={{xs:"column", md:"row"}} justifyContent="space-between" alignItems="center" gap={2} mt={2}>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/Customer2_Rep.jpg"} alt="Customer2_Rep" width="100%" maxWidth={400}></Box>
                        <Box>
                            <Box>新宿を拠点としたサッカークラブCriacao Shinjukuの運営を行っている株式会社Criacao(クリアソン)様。</Box>
                            <Box mt={2}>「スポーツの価値を通じて、真の豊かさを創造し続ける存在でありたい」という理念のもと、クラブ事業部、キャリア事業部、アスリート事業部という3つの柱で、体育会学生のキャリア形成支援や、地域や教育機関と連携したスポーツ推進など様々な事業を展開しています。</Box>
                            <Box mt={2}>今回は、現役のサッカー選手であると共に、経営企画室長、クラブ事業部の運営統括・事業統括等、多岐に渡る役職を兼務されている原田亮様にお話を伺いました。</Box>
                        </Box>
                        
                    </Stack>
                </Box>

                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">労働基準法を読み込んだ私が不安に思っていた内容も、クリアにしてくれた</Box>
                <Stack flexDirection={{xs:"column", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>
                    
                    <Box>
                        <Box fontWeight="bold">――『助成金コーディネート』とはどのように出会われたのでしょうか?</Box>
                        <Box mt={2}>日頃使用しているチャットツール上で、”助成金の受給額診断”を無料でしてもらえるという広告が目に入ったことがきっかけです。元々自社でも「キャリアアップ助成金」を活用していたのですが、もっと幅広く包括的に助成金のことを知りたいと思い、まずは受給額診断を受け、具体的な話を聞かせていただくことにしました。</Box>

                        <Box mt={5} fontWeight="bold">――『助成金コーディネート』に申し込んだ一番の決め手は何でしたか?</Box>
                        <Box mt={2}>一番の決め手は、助成金申請をしていくために必須となる労務周りの整備のサポートをしっかりしていただける点でした。労務の専門家であるコンサルタントがサポートしてくださり、労務周りの整備をしていけるということが、タイミング的にも当時の弊社が強く求めていることだったのです。</Box>
                    </Box>
                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/Customer2_01.jpg"} alt="Customer2_01" width="100%" maxWidth={500}></Box>
                    
                </Stack>

                <Box mt={10} fontWeight="bold">――労務周りの整備の必要性を強く認識されていた時期だったのですね。当時、労務周りで不安に感じていらっしゃったのはどのようなことだったのでしょうか?</Box>
                <Box mt={2}>弊社は現在、新宿区と「地域社会の発展」「スポーツの振興」「多文化共生の推進」等を目的に包括連携協定を結んでいます。当時はその締結をする前のタイミングで、行政との連携を高めていくためにも、胸を張って会社運営ができていると言える状態をつくっておきたいと考えていました。</Box>
                <Box mt={2}>会社運営の中でも特に私自身は、バックオフィス業務を統括する身として、社員が安心して働けるように労務周りをしっかり整えておくべきだと感じていました。ですが、社内に労務の専門家がいなかったので、まずは自分自身で労働基準法を読み込んでいた時期でしたね。</Box>
                <Box mt={2}>社員の中にはサッカー選手として活躍しているメンバーも雇用されており、多様な働き方を実現させる必要もありました。それを、具体的にどのように就業規則や雇用契約書などに落とし込んだら良いのか、どのように運営していけば良いのかが分からず苦戦していました。労働基準法を読み込んではいましたが、正しく理解できているのかも分からず不安でしたし、さらに、規則に落とし込んだ場合でも、抜けや漏れがないかなどの判断ができず、常に不安でした。</Box>
                <Box mt={2}>今考えると、このサービスに申し込んでいなかったら、どうやって労働基準法を遵守した労務周りの整備をしていたのだろう...と、危機感を持ちますね。</Box>

                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">専任のコンサルタントが伴走し、短期間で理想の労務整備ができた</Box>
                <Stack flexDirection={{xs:"column", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>

                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/Customer2_04.jpg"} alt="Customer2_04" width="100%" maxWidth={500}></Box>
                    <Box>
                        <Box fontWeight="bold">――実際に『助成金コーディネート』を受けてみて、いかがでしたか?</Box>
                        <Box mt={2}>労務の専門家であるコンサルタントの方の回答がいつも明瞭で、非常にやり取りがしやすかったです。コンサルタントの方には、チャットだけでなく、時には電話も活用しながら常に伴走していただいていました。こちらの緊急度・切迫度を汲み取っていただき、阿吽の呼吸で進めていけたと思います。結果的に、すごいスピード感で労務周りの整備が完了しました!</Box>

                        <Box fontWeight="bold" mt={5}>――社内で感じていた労務周りの課題は解消できましたか?</Box>
                        <Box mt={2}>はい、自社の思い描く労働環境を実現するための整備がしっかりできました。コンサルタントの方が、こちらの想いを全て受け止めて下さり、その想いを労働基準法を遵守する形で就業規則、雇用契約書、制度導入のための協定書、労使協定、帳簿などに落とし込みができたと思います。</Box>
                    </Box>
                    
                </Stack>

                <Box mt={5}>弊社は「スポーツの価値を通じて、真の豊かさを創造し続ける存在でありたい」という理念を掲げています。</Box>
                <Box mt={2}>会社の理念である真の豊かさを創造するためには、それを生み出していく社員が安心して働ける環境が必須です。会社の理念を、ただのきれいごとだけで終わらせないためにも、バックオフィスの側面からできることとして労務周りの整備は非常に大事なことだと思っています。自分たちの想いと実態が一致するためにも、労務周りをきちんと整備していくことで、その想いを実現できる土台ができたと感じています。</Box>

                

                
                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">採用に専念できる環境が整った</Box>
                <Stack flexDirection={{xs:"column", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>

                    <Box>
                        <Box fontWeight="bold">――『助成金コーディネート』を受けられて以降、社員数が2倍に増えていますよね!採用においても効果は感じられましたか?</Box>
                        <Box mt={2}>新入社員のスカウトをしている役員からは、採用に専念できるようになったと、感謝の声をもらうようになりました。バックオフィス側でしっかりと入社手続きができる体制が整ったので、採用が決まった後も安心してバックオフィスのメンバーに引き継ぐことができるようになったのだと思います。</Box>
                        <Box mt={2}>また、働く社員とそのご家族にとっても安心感につながってると感じています。</Box>

                        <Box mt={5} fontWeight="bold">――今後はどのように労務周りの整備をしていこうと考えていますか?</Box>
                        <Box mt={2}>『助成金コーディネート』の中で労務周りの整備していただいたので、法令を遵守することがどれだけ難易度の高いことなのかが分かりました。法改正がある度に自分たちで対応していくことは、自分の今のポジションや役割の多さを考えると対応しきれないと思っています。</Box>
                        <Box mt={2}>ですので、今後もTRIPORTさんのサービスを引き続き活用していきたいと思っています。継続的に労務の専門家のサポートを受けられることは心強いですし、法改正があったタイミングでもリアルタイムで情報をいただけたり、継続的に必要な労務周りのサポートをしていただけることは、本当に安心感があります。</Box>
                    </Box>
                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/Customer2_02.jpg"} alt="Customer2_02" width="100%" maxWidth={500}></Box>

                </Stack>

                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">血の通ったオンラインサービス/どんな会社でも、まずは一聞の価値あり!</Box>
                <Stack flexDirection={{xs:"column", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>
                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/Customer2_03.jpg"} alt="Customer2_03" width="100%" maxWidth={500}></Box>
                    <Box>
                        <Box fontWeight="bold">――最後に、『助成金コーディネート』は、どのような企業様におすすめできると思いますか?</Box>
                        <Box mt={2}>どんな会社でも、一度はTRIPORTの助成金アドバイザーやコンサルタントの方と話して欲しいと思いますね。なぜなら、まずは無料で受給額診断をしていただけて、助成金のことを包括的に知ることができ、それだけでなく、法令遵守するためにはどのような基準をクリアし会社運営をしていかなければならないのかも明白になるからです。</Box>
                        <Box mt={2}>経営者は、労務の専門家でないことも多いですし、たくさんの業務やタスクがある中で、労務周りの整備にまで目が行き届かないことも多いと思います。『助成金コーディネート』を活用することで、社内で見落としがちな労務周りの整備を行うことができ、会社の財産である社員の働く環境を整えることもできます。助成金や労務に関する最新情報や知識も提供していただけますし、とにかく、TRIPORTのコンサルタントの方には一度は会った方がいいよ!と言いたいですね。それくらい頼りになる存在です。</Box>
                        <Box mt={2}>クラウドサービスにもかかわらず、血の通ったコミュニケーションができると感じました。オンラインで完結するサービスで、ここまで血の通ったコミュニケーションができ、丁寧な対応をしていただけるのは、本当に珍しいと思います。</Box>
                    </Box>
                </Stack>

                <Box mt={10} fontWeight="bold">――これからもお客様との繋がりを大切にしたサービスを提供し続けたいと思います。原田様、本日はありがとうございました。</Box>
            </Box>
            <CustomersFooter/>
            <Footer/>
        </>
    );
}

export default Customer2;