import React from 'react';
import {Box,Stack} from '@mui/material'
import Header from "../LP/Header"
import Footer from '../LP/Footer';
import CustomersFooter from './CustomersFooter';


function Customer6() {

    const customerNo=6;

    return (
        <>
            <Header/>
            <Box className='section-content' margin="0 auto" fontSize={{xs:16, md:16}} pt={15} pb={15} pl={3} pr={3}>

                <Box component="h1" fontSize={30} textAlign="center" fontWeight="bold" color="#007bff" mb={10}>助成金サービスを申し込んだ決め手は、「人」の良さ。<br/>労務の専門家として頼れる存在</Box>

                <Box p={{xs:"20px",md:"30px 100px"}} backgroundColor="#efefef" >

                    <Stack flexDirection={{xs:"column-reverse", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>
                        <Box>
                            <Box fontSize={{xs:16, md:18}} fontWeight="bold" >株式会社アプトアミューズ</Box>
                            <Box mt={2}>業種：介護業</Box>
                            <Box mt={1}>サービス：訪問介護サービス、生活困窮者向け住居・食事の提供やサポートなど</Box>
                            <Box mt={1}>従業員数：10名</Box>
                        </Box>
                        {/* <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/Customer${customerNo}_Logo.png`} alt="Customer_Logo" width="100%" maxWidth={170}></Box> */}
                    </Stack>

                    <Stack flexDirection={{xs:"column", md:"row"}} justifyContent="space-between" alignItems="center" gap={2} mt={2}>
                        <Box >
                            <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/Customer${customerNo}_Rep.jpg`} alt="Customer_Rep" width="400px" maxWidth={{xs:"100%",md:"400px"}}></Box>
                        </Box>
                        <Box>
                            <Box>北海道北見市を中心に訪問介護サービスを提供する『株式会社アプトアミューズ』様。</Box>
                            <Box mt={2}>高齢者向け「かげつ訪問介護事業所」の運営のみならず、生活困窮者の住まいや食事のサポート、行政手続きの支援など、多岐にわたるサービスを展開しています。地域に根ざした活動を通じて、人々の暮らしを支え続ける同社の取り組みは、行政や地域住民からも頼られる存在として、地域福祉に大きく貢献しています。</Box>
                            <Box mt={2}>今回は、TRIPORTのサポートで「キャリアアップ助成金」「両立支援等助成金」など、複数の助成金を受給された、代表取締役の水口 武様にお話を伺いました。</Box>
                        </Box>
                        
                    </Stack>
                </Box>

                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">訪問介護サービスを軸に<br/>地域の人々を支える事業を展開する『株式会社アプトアミューズ』</Box>
                <Stack flexDirection={{xs:"column-reverse", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>
                    
                    <Box>
                        <Box fontWeight="bold">――御社は、どのような事業をされているのですか？</Box>
                        <Box mt={2}>メインの事業は訪問介護サービスです。現在事務所として使用している大きな建物を活用し、生活困窮者の住まいや食事のサポートも行っています。</Box>

                        <Box mt={5} fontWeight="bold">――独立前から介護のお仕事をされていたのでしょうか？</Box>
                        <Box mt={2}>元々、病院勤務にて介護の仕事をしていました。主に看護部介護課で管理職に就いていました。</Box>

                        <Box mt={5} fontWeight="bold">――元々、病院で管理やマネジメントをしていたんですね！</Box>
                        <Box mt={2}>はい。ちょっと余談なんですが、実はその当時、一緒に働いていた看護師長さんがとても尊敬できる方で、その方の役に立ちたいと思って社労士の資格を取ろうと思っていたんですよ。</Box>

                        <Box mt={5} fontWeight="bold">――社労士の資格ですか？！将来経営されることを見越して社労士の勉強もしたんでしょうか？</Box>
                        <Box mt={2}>当時はそこまでは考えていませんでしたが、私は看護部の配置基準管理を手伝ったりしていたこともあったので、社労士の知識があれば、私が総務部と看護部の架け橋になることができ、双方の部署の助けになるんじゃないかと思ったんです。結果的には、社労士の知識は難しく、資格取得は叶いませんでした。</Box>
                        <Box mt={2}>ただ、当時勉強したことで、社労士さんが労務の専門家としていかに従業員や会社にとって重要なことをやってくれているかがわかります。とても難しいことをやってくれていると思いますし、尊敬しています。</Box>
                    </Box>
                    
                    <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/Customer${customerNo}_01.jpg`} alt="Customer_01" width="500px" maxWidth={{xs:"100%",md:"500px"}}></Box>
                        
                </Stack>

                <Box mt={5} fontWeight="bold">――そこからどのような経緯で今の事業をスタートされたのでしょうか？</Box>
                <Box mt={2}>まずはケアマネージャーの資格を取得し、その後ケアマネジメントの仕事そのものを身に付けるために病院勤務から大手の会社に転職。実践経験を経て、7年前にケアマネージャーとして一人起業しました。独立3年目には事業拡大のために事務所を構え、従業員も雇い始め、訪問介護の事業をメインにシフトするとともに、生活困窮者への支援もスタートしました。</Box>

                <Box mt={5} fontWeight="bold">――生活困窮者への支援とはどのようなことをしているのですか？</Box>
                <Box mt={2}>住まいと食事の提供、それから、行政手続きの支援などです。今は行政（生活保護課）の方から相談されることや、地域の方からの紹介でうちに困っている方が来たりしています。</Box>

                <Box mt={5} fontWeight="bold">――高齢者だけでなく、地域のいろんな方々から頼りにされる存在ですね！</Box>




                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">助成金サービスを申し込んだ決め手は、「人」の良さ！<br/>審査の厳しい助成金実績が豊富なTRIPORTは、労務の専門家として頼れる存在</Box>
                <Box fontWeight="bold">――どのようなきっかけでTRIPORTと出会ったのでしょうか？</Box>
                <Box mt={2}>介護事業者向けのソフトとしてうちの会社が使っている「カイポケ」のサイトで、「カイポケ助成金診断」*という名前のサービスを見つけたことがきっかけです。</Box>
                <Box mt={2}>知り合いから紹介してもらうのではなく、自分で見つけたのが良かったと思います。私の場合、紹介者がいると、その方に気を使って本音で担当者と話せなくなってしまう気がするんです。ですが、TRIPORTのサービスについては「カイポケ」サイトで自ら見つけて問い合わせをし、ニュートラルにサービスの良し悪しや自分との相性を判断できたと思います。誰かに気を遣ったり遠慮することなく、本音で担当者とやり取りできました。</Box>
                <Box mt={2} fontSize={10}>* TRIPORTと株式会社エス・エム・エスは業務提携し、介護事業者に対して労働環境改善及び助成金活用の支援を行う『カイポケ助成金診断』を提供しています。実際の申請サポートはTRIPORTが行っています。</Box>
                
                <Box mt={5} fontWeight="bold">――他の助成金サービスも検討されたことはありましたか？</Box>
                <Box mt={2}>はい。実は今でも助成金サービスの話を聞いたりしています。日常的に助成金に関するサービスの営業って多いんですよ。私の場合は営業を断らず、全部話を聞くんです。もしかしたら自分が知らない助成金があるかもしれないですしね。情報収集のためにも。でも、TRIPORTさんより多くの助成金情報を持っている助成金サービスに出会ったことは、今だにありません。</Box>
                <Box mt={2}>他社の営業さんが、「これは知らない会社が多いんですよ、この助成金は知っていますか！？」と言って教えてくれた助成金も、すでにTRIPORTのサポートを通じて申請したものだったりします。</Box>

                <Box mt={5} fontWeight="bold">――TRIPORTの助成金サービスを受けようと思った決め手は何でしたか？</Box>
                <Box mt={2}>一番の決め手は、「人」です。初回にサービス説明をしてくれた担当者の人柄の良さと丁寧な対応がとても気に入りました。</Box>
                <Box mt={2}>士業の中には「先生、先生」と呼ばれ慣れてしまって、横柄な態度の方もいますが、TRIPORTの担当者は対応がとても丁寧で、こちらに寄り添ってくれる人でした。どれだけサービス内容が良くても、「人柄」が良くなければ申し込んでいません。TRIPORTの場合は、「この人なら任せられる」と確信できました。</Box>
                <Box mt={2}>もちろんサービス内容も信頼できると思いました。助成金の審査を通すためには、労務まわりもきちんと整備しないといけないし、申請時には遵法性もチェックされますよね。助成金というものをフックに、法令遵守しながら、労務をきちんと整備・対応できるサービスだと理解でき、労務の専門家として頼れる存在だと思いました。</Box>

                <Box mt={5} fontWeight="bold">――オンラインのサービスでありながら、「人」を感じていただけたこと、とても嬉しいです。</Box>


                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">TRIPORTのサポートで、「キャリアアップ助成金・障害者正社員化コース」を<br/>スムーズに申請・受給できた</Box>
                <Box mt={5} fontWeight="bold">――TRIPORTのサポートで、「キャリアアップ助成金」「両立支援等助成金」を受給されたと思いますが、中でも「キャリアアップ助成金・障害者正社員化コース」は、どのような経緯で活用することになったのでしょうか？</Box>
                <Box mt={2}>助成金の対象となった従業員とは、私が独立前に勤務していた職場で出会ったのですが、ハローワークで求人を出した際に応募してくれました。生まれつき心臓に障害があるため、障害者雇用ということになるのですが、私よりも体力もあり、介護の現場で大活躍していますよ。何よりとても人柄が良く、顧客からの信頼も厚いです。彼のためにもできるだけ働きやすい環境を整えようと考えています。</Box>
                <Box mt={2}>最初は有期契約で雇用をスタートしましたが、彼の活躍ぶりから正社員にすることを決め、その際に「キャリアアップ助成金・障害者正社員化コース」が活用できるということで、この助成金の申請対応をお願いしました。</Box>

                <Box mt={5} fontWeight="bold">――実際にTRIPORTの助成金サービスをご利用いただく中で、思いのほか大変だった・苦労した、という点はありましたか？</Box>
                <Box mt={2}>ありませんでした。わからないことや不安な部分は都度TRIPORTの労務・助成金担当者に確認し、自社で出勤簿や賃金台帳をしっかり付けていたので、助成金申請はスムーズに進みました。</Box>

                <Box mt={5} fontWeight="bold">――助成金申請のタイミングだけでなく、適宜きちんと相談していただきながら、日々の帳簿整備をしっかりされていらっしゃったから、申請時にも苦労を感じることなくスムーズに進めることができたんですね！とても素晴らしいですね。</Box>



                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">助成金は受給後の使い道が自由！<br/>労務・助成金の専門家にいつでも聞ける安心感</Box>
                <Box mt={5} fontWeight="bold">――TRIPORTのサービスで感じたメリットや効果はどのようなものでしたか？</Box>
                <Box mt={2}>とにかく助成金申請の対応を楽にできたことがメリットです。また、助成金は、受給した後の使い道が自由なのが助かりますね。会社として必要な設備投資に使ってもいいし、みんなのボーナスに充てるということもできるし、会社の資金としてとても助かります。</Box>

                <Box mt={5} fontWeight="bold">―― サービスを受ける前と後で、変化したことはありましたか？</Box>
                <Box mt={2}>労務に関して、いつでも専門家に相談できることが大きな安心感につながっています。</Box>
                <Box mt={2}>自分では正しいと思っていても、実際には間違えていることもありますし、私は労務のプロではないので、専門家に確認・指摘してもらえるのは心強いです。自分を過信せず、常に疑う姿勢を持つことを大切にしているので、すぐに相談できる相手がいるというのは、サービスを受ける前と後で大きな違いを感じています。</Box>




                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">TRIPORTのサービスは、経営者自身を守るための選択<br/>創業時から活用したら良いサービス</Box>
                <Box mt={5} fontWeight="bold">――最後に、TRIPORTのサービスは、どのような企業様におすすめできると思いますか？</Box>
                <Box mt={2}>TRIPORTは、経営者が自分を守るために必要なサービスだと感じています。私の周りの経営者にも「これは自分を守るために必要だよ」と勧めています。特に、創業間もない会社や小規模な会社は、労務に関して不安が多く、助成金を活用できる可能性も高いので、ぜひ利用すべきだと思います。</Box>
                <Box mt={2}>小さな会社は経費を節約したいと思いますが、何か問題があれば責任を取るのは経営者です。無知からの過失で、最悪の場合は倒産のリスクもあります。そうならないためにも、TRIPORTのサービスは必要な経費だと考えます。</Box>
                <Box mt={2}>独学で進めても、後々ミスが発覚することはよくあります。プロの目線でチェックしてもらえるのは非常に心強いです。</Box>


                <Box mt={5} fontWeight="bold">――水口さま、本日は貴重なお話をお聞かせいただきありがとうございました！<br/>インタビューを通して、 地域の方々に対しても、従業員に対しても、サービス提供者に対しても、「人」を大切にしていらっしゃる水口さまのお人柄が印象に残りました。従業員・会社・経営者さまを守るためにも、今後もTRIPORTのサービスを大いにお役立ていただけますと光栄です。</Box>
                

                <Stack mt={10} direction={{xs:"column",md:"row"}} alignItems="center">
                    <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/Customer_TRIStuff_01.png`} alt="TRIStuff" width="100%" maxWidth={80}></Box>
                    <Box width="50px" height="25px" sx={{clipPath:{xs:"polygon(50% 0, 0 100%, 100% 100%)",md:"polygon(0 50%, 100% 0, 100% 100%)"},transform:{xs:"translateY(5px)",md:"translateX(10px)"}}} backgroundColor="#D1EAFE"></Box>
                    <Box flex={1} p={2} backgroundColor="#D1EAFE">
                        <Box display="inline-block" p={1} fontWeight="bold" backgroundColor="white" borderRadius={50}>TRIPORTの労務・助成金担当者の声</Box>
                        <Box mt={1}>何度もやり取りを重ねるうちに、水口様のお人柄に惹かれ、実際にお会いした際も、とても人間味に溢れるアツイ社長さんだと感じました。現在は他の担当に引き継ぎましたが、今でもお付き合いさせていただき、一緒に飲食をする機会もあります。初めて担当させていただいてから約5年が経ちますが、今もTRIPORTを大切に思って頂けており嬉しい限りです。これからもどうぞよろしくお願いいたします！</Box>
                    </Box>
                    
                </Stack>
                <Stack mt={5} direction={{xs:"column",md:"row"}} alignItems="center">
                    <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/Customer_TRIStuff_02.png`} alt="TRIStuff" width="100%" maxWidth={80}></Box>
                    <Box width="50px" height="25px" sx={{clipPath:{xs:"polygon(50% 0, 0 100%, 100% 100%)",md:"polygon(0 50%, 100% 0, 100% 100%)"},transform:{xs:"translateY(5px)",md:"translateX(10px)"}}} backgroundColor="#D1EAFE"></Box>
                    <Box flex={1} p={2} backgroundColor="#D1EAFE">
                        <Box display="inline-block" p={1} fontWeight="bold" backgroundColor="white" borderRadius={50}>TRIPORTの労務・助成金担当者の声</Box>
                        <Box mt={1}>水口様はとても気さくにお話してくださる方で、TRIPORT社内でもファンがいるお客様です。お電話でお話しすると、冗談とユーモアを交えトークを和ませてくれますが、従業員や地域のことを真剣に考え、熱い思いを持っていらっしゃる方です。そんな水口様のために、労務面で少しでもお力になれたらと日々思っています。</Box>
                    </Box>
                    
                </Stack>

                <Stack mt={10} p={2} direction="row" flexWrap="wrap" backgroundColor="#EBEBEB" justifyContent="flex-start" gap={5}>
                    <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/Customer${customerNo}_02.jpg`} alt="Customer_02" width="600px" maxWidth="100%" ></Box>
                    <Box flex={1}>
                        <Box fontWeight="bold" flex={1} fontSize={18}>株式会社アプトアミューズ</Box>
                        <Box mt={2} ml={1}>
                            <Box>北海道北見市にて指定訪問介護事業所「かげつ訪問介護事業所」の運営をしている。</Box>
                            <Box mt={2}>要介護状態、要支援状態等にある利用者が、可能な限りその居宅において、その有する能力に応じ自立した日常生活を営む事が出来るよう、身体介護その他の生活全般に渡る援助を行い、地域住民の福祉の増進に貢献している。</Box>
                        </Box>
                    </Box>
                </Stack>
            </Box>

            <CustomersFooter/>
            <Footer/>
        </>
    );
}

export default Customer6;