import React from 'react';
import { useSearchParams } from "react-router-dom";
import {Box,Stack,Link} from '@mui/material'

function TopSection20(){
    const [searchParams] = useSearchParams();

    let queryString = searchParams.toString()
    if(queryString?.length > 0){
        queryString = `?${queryString}`;
    }

    const customersList =[
        {
            id:"nicoichi",
            accountName:"株式会社にこいち",
            imageName:"TOP_casestudy1",
            industry:"広告業",
            employee:"7名",
            title:<>従業員にとって<br></br>働きやすい環境がつくれた</>,
            detail:"助成金コーディネートを導入して従業員の働きやすい環境づくりができ、会社の利益にもなりました。まずは従業員が働きやすい環境をしっかりとつくり、その結果、助成金が受給できるというサービスで本当の意味で会社の成長を応援してくれるサービスです。"
        },
        {
            id:"criacao",
            accountName:"株式会社Criacao",
            imageName:"TOP_casestudy2",
            industry:"サービス業",
            employee:"31名",
            title:<>安心して採用ができ<br></br>従業員が2倍に</>,
            detail:"自社の思い描く労働環境を実現するための整備がしっかりできました。労働基準法を遵守する形で就業規則、雇用契約書、制度導入のための協定書、労使協定を帳簿などに落とし込みができ、採用担当が採用に専念できる環境と働く社員とそのご家族まで安心できる環境が整いました。"
        },
        {
            id:"overcome-music",
            accountName:"株式会社OVERCOME MUSIC",
            imageName:"TOP_casestudy3",
            industry:"情報通信業",
            employee:"3名",
            title:<>社長が何役も兼任している企業に<br></br>オススメ</>,
            detail:"小規模の会社や個人事業主から法人成りしたばかりの会社さんには経営者が本業に集中できることが、会社の業績を上げるために重要です。助成金コーディネートは手間が少なく、自社の労務周りの整備から助成金受給までしっかりと行われるかつ経営リスク回避、社員に安心して働いてもらえる環境まで整えられます。"
        },
        
    ]

    const styledLink=(url,label)=>{
        return(<Link href={url} target="_blank" backgroundColor="#4771DF" borderColor="#4771DF" border={3} sx={{
            color:"white",
            textDecoration:"none",
            "&:hover":{
                backgroundColor:"white",
                color:"#4771DF"
            }
            }} fontWeight="bold" textAlign="center" mt={2} p={2} width={200} borderRadius={10}>
            <Box position="relative" sx={{
                "&::after":{
                    content:'"〉"',
                    position:"absolute",
                    right:"0",
                }
            }}>{label===void 0 ? "詳しく見る":label}</Box>
        </Link>)
    }

    return (
        <Box component="section" backgroundColor="white" pt={10} pb={10}>
            <Box className='section-content' >
                <Box component="h1" textAlign="center" fontSize={{xs:20, md:30}} fontWeight="bold">導入事例</Box>
                <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={2} mb={5}></Box>

                <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="center" gap={3}>

                {customersList.map((item,index) => (
                            <Link display="flex" flex={1} color="inherit" flexBasis="30%" href={`/customers/${item.id}${queryString}`} sx={{textDecoration:"none"}}>
                            <Stack  p={2} boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px" borderRadius={5} >                
                                <Box>
                                    <Box backgroundColor="#4771DF" color="white" fontSize={16} fontWeight="bold" p={1} textAlign="left">{item.accountName}</Box>
                                    <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/${item.imageName}.jpg`} alt={item.imageName} width="100%"></Box>
                                </Box>
                                <Box mt={1} >
                                    <Stack component="h3" flexDirection="row" fontSize={11} gap={1}>
                                        <Box width={70} textAlign="center" backgroundColor="#D1EAFE" fontWeight="bold">業　種</Box>
                                        <Box>{item.industry}</Box>
                                    </Stack>
                                    <Stack flexDirection="row" fontSize={11} mt={1} gap={1}>
                                        <Box width={70} textAlign="center" backgroundColor="#D1EAFE" fontWeight="bold">従業員数</Box>
                                        <Box>{item.employee}</Box>
                                    </Stack>
                                </Box>
                                <Box fontSize={22} fontWeight="bold" borderTop="1px dotted gray" mt={1} pt={1} textAlign="left">{item.title}</Box>
                                <Box flex={1} fontSize={14} borderTop="1px dotted gray" mt={1} pt={1} textAlign="left">{item.detail}</Box>
                                <Stack alignItems="center">{styledLink(`/customers/${item.id}${queryString}`)}</Stack>
                            </Stack>
                            </Link>
                        )
                    )}

                </Stack>

                <Stack alignItems="center" mt={5}>
                    {styledLink("/customers"+queryString,"導入事例の一覧")}
                </Stack>
                
            </Box>
        </Box>
    )
}

export default TopSection20;