import React from 'react';
import {Box,Stack,Link} from '@mui/material'
import Header from "../LP/Header"
import Footer from '../LP/Footer';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CustomersFooter from './CustomersFooter';


function Customer4() {

    const customerNo=4;

    return (
        <>
            <Header/>
            <Box className='section-content' margin="0 auto" fontSize={{xs:16, md:16}} pt={15} pb={15} pl={3} pr={3}>

                <Box component="h1" fontSize={30} textAlign="center" fontWeight="bold" color="#007bff" mb={10}>人手不足が解消！<br/>業務を効率化する設備購入費の90%が助成金で賄えた！</Box>

                <Box p={{xs:"20px",md:"30px 100px"}} backgroundColor="#efefef" >

                    <Stack flexDirection={{xs:"column-reverse", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>
                        <Box>
                            <Box fontSize={{xs:16, md:18}} fontWeight="bold" >神谷荘</Box>
                            <Box mt={2}>業種：宿泊業・飲食業</Box>
                            <Box mt={1}>サービス：ホテル/レストランの運営/農業</Box>
                            <Box mt={1}>従業員数：7名</Box>
                        </Box>
                        <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/Customer${customerNo}_Logo.png`} alt="Customer_Logo" width="100%" maxWidth={170}></Box>
                    </Stack>

                    <Stack flexDirection={{xs:"column", md:"row"}} justifyContent="space-between" alignItems="center" gap={2} mt={2}>
                        <Box >
                            <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/Customer${customerNo}_Rep.jpg`} alt="Customer_Rep" width="400px" maxWidth={{xs:"100%",md:"400px"}}></Box>
                            <Box backgroundColor="white" textAlign="center" fontSize={10}>『神谷荘』3代目代表・神谷 恭平 様</Box>
                        </Box>
                        <Box>
                            <Box>沖縄の離島・津堅島で宿泊施設やレストランを運営する『神谷荘』様。</Box>
                            <Box mt={2}>沖縄本島からフェリーで30分の距離にある津堅島は、都会の喧騒を離れた自然豊かな場所で、特に夏には多くの観光客で賑わいます。『神谷荘』は宿泊施設の運営に加え、レストラン、マリンスポーツ、民謡ショー、ウェデイングプランなど多彩なサービスを提供しています。</Box>
                            <Box mt={2}>今回は『神谷荘』の神谷ゆかり様にお話を伺いました。</Box>
                        </Box>
                        
                    </Stack>
                </Box>

                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">「何もないが<Box component="span" fontFamily="ms mincho">〝</Box>ある<Box component="span" fontFamily="ms mincho">〟</Box>島」<br/>沖縄の離島・津堅島で、宿泊施設やレストランを経営する『神谷荘』</Box>
                <Stack flexDirection={{xs:"column-reverse", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>
                    
                    <Box>
                        <Box fontWeight="bold">――『神谷荘』さんは、どのような事業をされているのですか？</Box>
                        <Box mt={2}>沖縄の本島（平敷屋港）からフェリーで30分で行ける離島・津堅島で、宿泊施設とレストランを運営しています。今は夏・繁忙期のため、今週末も綺麗なビーチ前でBBQができるコースで100名以上のお客様の予約があります。</Box>
                        <Box mt={2}>県内外から、また最近は海外から幅広い年齢層の方が来られます。マリンスポーツやBBQ、民謡ショーや島内観光などをされて日帰りする方もいますし、宿泊して津堅島の綺麗な夕日と星空まで堪能する方もいます。最近はヴィーガン対応の食事や、1棟丸ごと貸切プラン、ビーチウェディング、フォトウェディングなどの新たな取り組みも始めています。</Box>
                        <Box mt={2}>何も無い島なんですけど、それが皆さん、いいみたいですね。</Box>

                        <Box mt={5} fontWeight="bold">――『神谷荘』さんのキャッチコピーにもある「何もないが <Box component="span" fontFamily="ms mincho">〝</Box>ある<Box component="span" fontFamily="ms mincho">〟</Box>島」ですね！<br/>「何もない」という贅沢に人々が魅了され、癒しのスポットとなっているんでしょうね。</Box>
                    </Box>
                    <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/Customer${customerNo}_01.jpg`} alt="Customer_01" width="100%" maxWidth={500}></Box>
                    
                </Stack>

                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">慢性的な人手不足に悩んでいた</Box>
                <Stack flexDirection={{xs:"column", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>

                    <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/Customer${customerNo}_02.jpg`} alt="Customer_02" width="100%" maxWidth={500}></Box>
                    <Box>
                        <Box fontWeight="bold">――従業員何名で、宿泊施設＆レストランの業務を回していたのでしょうか？</Box>
                        <Box mt={2}>従業員は、正社員4人に加え、週に数日働いてるアルバイトなどが3人。この人数で、宿泊施設・レストラン全ての業務を回していました。</Box>
                        <Box mt={2}>特に繁忙期のレストランは大忙しで、毎日大量の食器が使われるため、洗い場に付きっきりになる人が必要でした。1人の従業員が、朝から晩まで1日中お皿洗いしかしていない…、なんてことも…。</Box>
                    </Box>
                    
                </Stack>

                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">助成金で購入費の90%を賄えた！<br/>食洗機の導入で、従業員を何人も雇ったかのような効果を実感！</Box>
                <Stack flexDirection={{xs:"column-reverse", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>

                    <Box>
                        <Box fontWeight="bold">――慢性的な人手不足について、どのように解決したのですか？</Box>
                        <Box mt={2}>慢性的な人手不足を解決するために、助成金が活用できることをTRIPORTさんから教えてもらいました。お皿洗いのために、貴重な従業員の手が取られてしまう毎日から解放されるために食洗機を買おうとしたところ、食洗機の購入にも助成金が活用できることをTRIPORTさんに提案していただきました。</Box>
                        
                        <Box mt={5} fontWeight="bold">――TRIPORTのサポートで、具体的にどのような助成金を活用しましたか？</Box>
                        <Box mt={2}>食洗機の購入に活用したのは、「業務改善助成金」です。レストラン業務の効率化のために、90万円の食洗機を購入しましたが、購入費の約90% 80万円が助成金として支給されました！</Box>
                        <Box mt={2}>慢性的な人材不足という悩みを解決するために必要な設備投資でしたが、全額を自社で負担するのは大変…。でも、助成金を活用することで自社での負担が軽減され、そのおかげで設備購入ができ、人材不足という課題を大きく改善できたと思います。</Box>
                        
                        
                    </Box>
                    <Box>
                        <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/Customer${customerNo}_03.jpg`} alt="Customer_03" width="500px" maxWidth={{xs:"100%",md:"500px"}}></Box>
                        <Box fontSize={10} textAlign="center">TRIPORTのメンバーも『神谷壮』さんを訪問してみました（2024年4月）</Box>
                    </Box>

                </Stack>
                
                <Box mt={5} fontWeight="bold">――どのような効果を実感していますか？</Box>
                        <Box mt={2}>この食洗機のおかげで感動するほど日々の業務が楽になりました。食洗機のことをみんな、「あらいさん（洗いさん）」という愛称で呼んでるくらいです（笑）。スタッフも本当に喜んでいて、みんなで感謝しています！</Box>
                        <Box mt={2}>他にもアルバイトだった方が新たに正社員になった際にも、TRIPORTのサポートで「キャリアアップ助成金・正社員化コース」を活用しましたが、新たな設備購入に対しても使える助成金を知れたこと、そして実際に活用できたことは本当に大きかったです！</Box>

                <Box mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">TRIPORTは、助成金の知識・ナレッジが豊富！<br/>助成金やサービスへの疑問にも全て回答してくれた、信用できる会社</Box>
                <Stack flexDirection={{xs:"column", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>
                    <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/Customer${customerNo}_04.jpg`} alt="Customer_04" width="100%" maxWidth={500}></Box>
                    <Box>
                        <Box fontWeight="bold">――ところで神谷さんは、TRIPORTに出会う以前から、助成金のことをご存じだったのでしょうか？</Box>
                        <Box mt={2}>食洗機の購入に使える助成金があるということは知りませんでした！</Box>
                        <Box mt={2}>助成金というもの自体については、コロナ禍で「雇用調整助成金*」を自社で申請していたので知っていました。また、「キャリアアップ助成金」というものがあることも知っていました。でも、助成金の要件を確認するだけでも資料が多く、読むのがめんどくさくて…（笑）。なので、詳しくは知りませんでしたし、もちろん十分に活用もできていませんでした。</Box>

                        <Box mt={2} fontSize={10}>*雇用調整助成金（新型コロナウイルス感染症の影響に伴う特例）：「新型コロナウイルス感染症の影響」により、「事業活動の縮小」を余儀なくされた場合に、従業員の雇用維持を図るために、「労使間の協定」に基づき、「雇用調整（休業）」を実施する事業主に対して、休業手当などの一部を助成するもの</Box>
                    </Box>
                </Stack>

                <Box mt={5} fontWeight="bold">――どのようにしてTRIPORTの「助成金コーディネート」サービスを知りましたか？</Box>
                <Box mt={2}>自社でもらえる助成金があるならもらいたい、と思って助成金や関連サービスについて調べていた中で、TRIPORTの「助成金コーディネート」サービスを見つけました。実はTRIPORTのサービス以外にも、他の事業会社さんが提供している助成金サービスについても調べていて、実際にいろんなところから助成金やそれぞれのサービスの説明を聞いたりもしていたんです。</Box>

                <Box mt={5} fontWeight="bold">――複数の助成金サービスを検討された中で、TRIPORTの「助成金コーディネート」サービスに申し込んだ決め手は何でしたか？</Box>
                <Box mt={2}>助成金やサービスについて疑問に思うことをこちらから色々と細かく聞いても、TRIPORTの担当者は全てにちゃんと回答してくれた点が信用できると思いました。</Box>
                <Box mt={2}>他の会社さんは、アポの取り方や説明が「詐欺っぽいな…」と感じるようなところもあって…。そういう会社さんについては、やり取りだけでも疲れてしまったので、途中でお断りしました。他にも、こちらからの質問に回答できなかったり、 知識不足を感じる発言も多く、信用できない会社もありました。</Box>
                <Box mt={2}>あまり信用できない事業会社が多い業界だと感じる中、TRIPORTは当たり前のことをきちんと当たり前に対応してくれるところが安心できる、と感じました。</Box>


                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">サービス満足度 1,000%！<br/>宿泊業・飲食店でも使える！設備購入したい会社にオススメ</Box>
                <Stack flexDirection={{xs:"column-reverse", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>
                    
                    <Box>
                        <Box fontWeight="bold">――「助成金コーディネート」サービスへの満足度を教えてください。</Box>
                        <Box mt={2}>満足度は1,000%です。</Box>

                        <Box mt={5} fontWeight="bold">――1,000%ですか？！（笑）そんなにご満足いただけて嬉しいです。</Box>
                        <Box mt={2}>助成金を受給できるのみならず、労務のことを色々と教えてくれる専門家として、いつも日頃から頼りにしています。今後、宿泊施設の方も業務効率化のための設備投資を考えているので、引き続き相談させてください。</Box>
                        
                    </Box>
                    <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/Customer${customerNo}_05.jpg`} alt="Customer_05" width="100%" maxWidth={500}></Box>

                </Stack>

                <Box mt={5} fontWeight="bold">――最後に、『助成金コーディネート』は、どのような企業様におすすめできると思いますか？</Box>
                <Box mt={2}>TRIPORTに出会う以前から、ITによる業務効率化に使える助成金の話は聞いたことはあったんですけど、宿泊業・飲食業にはハードルが高い＆身近に感じていないものが多かったんです。でも、宿泊業・飲食業でも使える助成金があることを教えてもらい、とても身近に感じました。</Box>
                <Box mt={2}>私たちと同じように宿泊業・飲食業を経営されている経営者さんに、TRIPORTの「助成金コーディネート」をおすすめしたいですね。</Box>
                <Box mt={2}>実際、私の周りで、人手不足に困っていたり、新たに業務効率化のための設備購入したいと考えている経営者さんがいたら「TRIPORTさんに相談したらいいよ。まずは話を聞いてみたらいいよ！」とおすすめしています。</Box>

                <Box mt={5} fontWeight="bold">――神谷さん、本日はありがとうございました！ 助成金を活用し、人手不足が大幅に軽減したと聞いて、とても嬉しくなりました！<br/>これからも、素敵な島で、素敵な宿泊施設・レストランを経営する『神谷荘』さんを、TRIPORT一同、全力でサポートさせていただきたいと思います。</Box>

                <Stack mt={10} direction={{xs:"column",md:"row"}} alignItems="center">
                    <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/Customer_TRIStuff_01.png`} alt="TRIStuff" width="100%" maxWidth={80}></Box>
                    <Box width="50px" height="25px" sx={{clipPath:{xs:"polygon(50% 0, 0 100%, 100% 100%)",md:"polygon(0 50%, 100% 0, 100% 100%)"},transform:{xs:"translateY(5px)",md:"translateX(10px)"}}} backgroundColor="#D1EAFE"></Box>
                    <Box flex={1} p={2} backgroundColor="#D1EAFE">
                        <Box display="inline-block" p={1} fontWeight="bold" backgroundColor="white" borderRadius={50}>TRIPORTの労務・助成金担当者の声</Box>
                        <Box mt={1}>TRIPORTのサービスにお申し込み頂いた直後、神谷壮さんの悩みは「人手不足」でした。なんとかこのお悩みを解消したいとスタートした助成金の活用。結果ぴったりとはまり、導入した食洗器もすぐにスタッフの一員となる事が出来ました。やりとりの中で、神谷壮さんのある沖縄県の津堅島に行ってみたい！と弊社代表の岡本と共に訪問させて頂きました。津堅島、神谷壮さん、スタッフの皆さん全てが素敵過ぎました。神谷壮さんの皆様とＢＢＱとビールを飲みながら宿泊もさせて頂きました。今ではすっかり家族のような神谷壮さん。これからも家族ぐるみのお付き合いよろしくお願いいたします！！</Box>
                    </Box>
                    
                </Stack>

                <Stack mt={10} p={2} direction="row" flexWrap="wrap" backgroundColor="#EBEBEB" justifyContent="flex-start" gap={5}>
                    <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/Customer${customerNo}_06.jpg`} alt="Customer_06" width="600px" maxWidth="100%" ></Box>
                    <Box >
                        <Box >
                            <Box component="span" fontWeight="bold" mr={1} fontSize={18}>神谷荘</Box>
                            <Link href="https://www.kamiyasou.com/" target="_blank">https://www.kamiyasou.com/</Link>
                        </Box>
                        <Box mt={2} ml={1}>
                            <Box fontWeight="bold">宿泊プラン一例（税込）</Box>
                            <Stack direction={{xs:"column",md:"row"}} spacing={1} >
                                <Box><Box component="span" backgroundColor="#D9D9D9" pl={1} pr={1}>大人</Box>¥8,000</Box>
                                <Box><Box component="span" backgroundColor="#D9D9D9" pl={1} pr={1}>小人</Box>¥5,000</Box>
                                <Box><Box component="span" backgroundColor="#D9D9D9" pl={1} pr={1}>幼児</Box>無料</Box>
                            </Stack>

                            <Box mt={2} fontWeight="bold">１棟まるごと貸切プラン</Box>
                            <Box>15名-貸切可能（料金要相談）</Box>
                            <Stack direction="row" alignItems="center">
                                <LocationOnIcon/>
                                <Box flex={1}>アクセス：<Link href="https://www.kamiyasou.com/access" target="_blank" sx={{wordBreak:"break-all"}}>https://www.kamiyasou.com/access</Link></Box>
                                
                            </Stack>
                        </Box>
                    </Box>
                </Stack>
            </Box>

            <CustomersFooter/>
            <Footer/>
        </>
    );
}

export default Customer4;