import React from 'react';
import {Box,Stack} from '@mui/material'
import Header from "../LP/Header"
import Footer from '../LP/Footer';
import CustomersFooter from './CustomersFooter';


function Customer3() {

    return (
        <>
            <Header/>
            <Box className='section-content' margin="0 auto" fontSize={{xs:16, md:16}} pt={15} pb={15} pl={3} pr={3}>

                <Box component="h1" fontSize={30} textAlign="center" fontWeight="bold" color="#007bff" mb={10}>助成金のみならず、社員が安心して働ける環境も手に入れることができた</Box>

                <Box p={{xs:"20px",md:"30px 100px"}} backgroundColor="#efefef" >

                    <Stack flexDirection={{xs:"column-reverse", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>
                        <Box>
                            <Box fontSize={{xs:16, md:18}} fontWeight="bold">株式会社OVERCOME MUSIC</Box>
                            <Box mt={2}>業種：情報通信業、サービス業</Box>
                            <Box mt={1}>舞台、映像の音楽制作、音楽クリエイターのマネジメント 等</Box>
                            <Box mt={1}>従業員数：3名</Box>
                        </Box>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/Customer3_Logo.jpg"} alt="Customer3_Logo" width="100%" maxWidth={300}></Box>
                    </Stack>

                    <Stack flexDirection={{xs:"column", md:"row"}} justifyContent="space-between" alignItems="center" gap={2} mt={2}>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/Customer3_Rep.jpg"} alt="Customer3_Rep" width="100%" maxWidth={300}></Box>
                        <Box>
                            <Box>2.5次元ミュージカルをはじめとする舞台や、映像の音楽制作を手掛けると共に、音楽クリエイターたちの活躍の場を斡旋・サポート・マネジメントを行っている株式会社OVERCOME MUSIC様。</Box>
                            <Box mt={2}>代表取締役の楠瀬 拓哉様は、有名なグループバンドのドラマーからキャリアをスタート。多彩な表現力で、個人事業主として活躍の場を広げた後、会社を設立。音楽業界で働く同業者が安定的に、安心して働ける環境を創出するためにも、音楽クリエイターたちを雇用し活躍の場を提供しています。</Box>
                            <Box mt={2}>今回は、その楠瀬様にお話を伺いました。</Box>
                        </Box>
                        
                    </Stack>
                </Box>

                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">社員が安心して働ける環境を生み出したかった</Box>
                <Stack flexDirection={{xs:"column", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>
                    
                    <Box>
                        <Box fontWeight="bold">――楠瀬様は日頃、経営のお仕事だけでなく、ドラマーとしてもご活躍されているんですよね？</Box>
                        <Box mt={2}>はい。僕の最初のキャリアは実は「ヒステリックブルー」というバンドのドラマーだったんです。</Box>

                        <Box mt={5} fontWeight="bold">――えっ！！？そうだったんですね！！！学生時代、本当によく聴いていました！！どのような経緯で今の会社を設立されたのでしょうか？</Box>
                        <Box mt={2}>バンドとしても非常に有名になりましたが、その後バンドが解散したタイミングで、まずは個人事業主として独立しました。音楽業界で仕事を続けたいという想いから、ドラマーとしての仕事だけでなく、作詞・作曲や2.5次元ミュージカル等の舞台音楽の制作なども手掛けてきました。</Box>
                        <Box mt={2}>ただ、音楽業界での仕事はあまり安定せず、そのことに一番不安を感じていたんです。そこで、売り上げが立ってきたタイミングで会社を立ち上げると共に、同じような不安を抱えながらも頑張っている同業者の後輩達を雇い、彼らに安定的に音楽の仕事を提供できる体制をつくってきました。</Box>
                    </Box>
                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/Customer3_05.jpg"} alt="Customer3_05" width="100%" maxWidth={500}></Box>
                    
                </Stack>

                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">複数の助成金を自社で把握するのは困難／本業に集中するために必要なサービスだと確信</Box>
                <Stack flexDirection={{xs:"column", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>

                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/Customer3_01.jpg"} alt="Customer3_01" width="100%" maxWidth={500}></Box>
                    <Box>
                        <Box fontWeight="bold">――『助成金コーディネート』とはどのように出会われたのですか？</Box>
                        <Box mt={2}>当時の僕は、「助成金」という言葉すら知りませんでしたが、仕事で使用しているチャットツール上で、”助成金の受給額診断”を無料でしてもらえるという広告を見かけたんです。「助成金ってことはお金がもらえるということ…？これはお得なのかもしれない！」と思い、興味本位で広告をクリックしたことがきっかけです。</Box>
                        <Box mt={2}>ちょうど社員も増えてきたタイミングだったのですが、変化の激しい音楽業界であっても、会社の経営を継続的に安定させ、社員が安心して長く働ける環境をつくるにはどうすれば良いのだろう、と考えていた時でした。会社として得られる権利があるものは、きちんと享受したい。知らないだけで損をしたり、せっかくのチャンスを逃してしまっているのであれば、会社のメリットになる情報は知っておきたいし、助成金制度を積極的に活用したいという思いで、まずは無料の受給額診断をしてもらいました。</Box>

                        <Box fontWeight="bold" mt={5}>――その後、助成金アドバイザーからの詳しいお話を聞かれて、どのような感想を持たれましたか？</Box>
                        <Box mt={2}>実は、助成金アドバイザーの方から詳しい説明を聞いたのは、「受給額診断」を受けてから約1年後でした。本業である音楽活動も忙しく、先延ばしにしていたのですが、「やっぱり話だけは聞いてみよう！」と思い、改めてこちらからご連絡をして詳しいお話を聞くことにしました。</Box>
                        <Box mt={2}>お話を聞いた時、「これはもっと早く聞いておくべきだった！」と思いましたね。活用できる複数の助成金全てを、社内で把握して取り組むためには、知識も労力も集中力も必要で、非常に難しいと思いました。労務と助成金申請のプロにサポートしてもらうのが、格段に確実で格段に楽だ、と確信しました。</Box>
                    </Box>
                    
                </Stack>

                <Box mt={10} fontWeight="bold">――『助成金コーディネート』に申し込んだ一番の決め手は何でしたか？</Box>
                <Box mt={2}>助成金アドバイザーの方のお話を聞いて、不安が解消されたからです。僕が感じていた不安は「余計な手間が増えて、本業に集中できなくなってしまうこと」でした。自分自身が会社の中で何役も兼任しており、経営、労務、総務、プレイヤーとしての現場の仕事を抱えながら、さらに負担が増えるのはきついな…と思っていました。</Box>
                <Box mt={2}>しかし、助成金アドバイザーのお話を聞くうちに「助成金申請はやらないと損だ」「プロのサポートを受けながら取り組めば本業に集中して助成金申請に取り組める」と理解できたので、その場ですぐに申し込みをしました。</Box>
                <Box mt={2}>話してくださった助成金アドバイザーの方の人間性、話の明瞭さも決め手となりましたね。「この人に相談したい！任せられる！」と思ったんです。</Box>


                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">労務や助成金申請のプロから、現場の最新情報を瞬時に聞ける安心感</Box>
                <Stack flexDirection={{xs:"column", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>

                    <Box>
                        <Box fontWeight="bold">――実際に『助成金コーディネート』を受けてみて、いかがでしたか？</Box>
                        <Box mt={2}>助成金アドバイザーの方も、コンサルタントの方々も皆さんとても丁寧で、安心してお任せができました！</Box>
                        <Box mt={2}>僕は労務周りのことは知らないことだらけでしたが、それでも全て１から教えてくれたので本当に心強かったです。プロからサポートを受けているということが、会社を運営していく上でもとても頼もしかったです。</Box>

                        <Box mt={5} fontWeight="bold">――常時チャットでやり取りができるサポート体制はいかがでしたか？</Box>
                        <Box mt={2}>いつでもチャットですぐにプロに相談できるということも、ものすごくありがたかったです！</Box>
                        <Box>労基署やハローワークは17:00で閉まってしまいますが、音楽業をしていると、日中の対応や問い合わせができないことも多々あります。</Box>
                        <Box>でもチャットでプロの方からサポートを受けられるこのサービスは、自分のタイミングでメッセージを送っておくこともでき、すごく助かりました！</Box>
                    </Box>
                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/Customer3_03.jpg"} alt="Customer3_03" width="100%" maxWidth={500}></Box>

                </Stack>

                <Box mt={2}>また、TRIPORTのコンサルタントの方と話をしたり、必要な労務周りの整備をしていく中で、自分が元々やりたかった安定した雇用環境を作っていくためには、労務周りの整備が必要だったのだ、と気づくこともできたんです。</Box>
                <Box mt={2}>どの業界でも苦労することはあると思いますが、僕は自分の経験を伝えることで、後輩達が必要以上に苦しんだりすることなく、最短距離で次のステップへと進めるように助けてあげたいと思っているんです。だから後輩が会社を立ち上げたり、独立する時には「安定経営のためには労務周りの整備が重要なんだ」と教えてあげたいですね。TRIPORTさんのようなサービスを利用することで、本業の時間を確保しながら、気軽に労務の相談に乗ってもらえる環境が手に入ることも伝えていきたいです。</Box>

                <Box mt={10} fontWeight="bold">――コロナ禍でも弊社サービスはお役に立てましたでしょうか。</Box>
                <Box mt={2}>コロナ禍ではコロナ特例の助成金も新たに出てきましたが、受給内容や要件が短期間でどんどん変化することもありました。そんな時でも、プロのネットワークから得られる最新情報をリアルタイムでいただけていたので、不安なく申請できましたし、受給もできたので、本当に助かりました！身近にすぐに相談できるプロがいることは心強かったです。</Box>


                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">助成金を受給できただけでなく、社会的にも認定されたという事実にも自信がついた！</Box>
                <Stack flexDirection={{xs:"column", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>
                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/Customer3_04.jpg"} alt="Customer3_04" width="100%" maxWidth={500}></Box>
                    <Box>
                        <Box fontWeight="bold">――最近、新たに助成金が支給されましたよね！おめでとうございます！お気持ちはいかがですか？</Box>
                        <Box mt={2}>振り返ってみると、面倒なことがゼロだったわけではないんです。ですが、その面倒さこそが、僕がずっと求めてきた「安定・安心」には不可欠なことだったのだと理解しています。</Box>
                        <Box >もしあの時に『助成金コーディネート』に申し込んでいなかったら…労務が分からないまま会社経営をすることは、ヘルメットなしでバイクに乗っているようなもの。何も起こらなければ問題ないけれど、もし事故にあってしまったら大怪我に繋がってしまう。そんな状態だったと思います。</Box>
                        <Box mt={2}>『助成金コーディネート』のおかげで、労務周りが整えられて経営リスクを回避できただけでなく、助成金申請においても、自分がいつまでに何をしたら良いのかをナビゲートしてもらえたので、本業の時間を確保しながら、助成金を受給することができました。もしもプロのサポ―トなしに助成金申請に取り組んでいたら、本業が圧迫されるほどの労力とコストが発生していたのではないかと思います。</Box>
                    </Box>
                </Stack>

                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">社長が何役も兼任している中小・零細企業にオススメ</Box>
                <Stack flexDirection={{xs:"column", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>
                    <Box>
                        <Box fontWeight="bold">――最後に、『助成金コーディネート』は、どのような企業様におすすめできると思いますか？</Box>
                        <Box mt={2}>経営者自身がプレイヤーも兼任しているような小規模の会社さんや、個人事業主から法人成りしたばかりの会社さんにはお薦めできると感じます。プロのサポートを受けることで、経営者が自分の時間を確保して本業に集中することは、会社の業績を上げるために重要だと思います。</Box>
                        <Box>また、労務周りを整備して助成金を受給できているということは、国に認められた存在であると言えますし、他社との差別化にも繋がると思います。</Box>
                        <Box>助成金を受給できることはもちろん大切ですが、経営リスクを回避して、経営を安定させ、社員に安心して働いてもらうことが出来れば、会社がもっと強くなるような気がしますよね。</Box>
                        <Box mt={2}>労務周りの整備をして会社として強くなったように感じます。そして、助成金も受給でき、本当に良かったです！</Box>
                    </Box>
                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/Customer3_02.jpg"} alt="Customer2_03" width="100%" maxWidth={500}></Box>
                </Stack>

                <Box mt={10} fontWeight="bold">――助成金活用を通し、お客様の安定経営に貢献できることはTRIPORTの喜びです。楠瀬様、本日はありがとうございました。</Box>
            </Box>
            <CustomersFooter/>
            <Footer/>
        </>
    );
}

export default Customer3;