import React from 'react';
import {Box,Stack,Link} from '@mui/material'
import Header from "../LP/Header"
import Footer from '../LP/Footer';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import CustomersFooter from './CustomersFooter';


function Customer5() {

    const customerNo=5;

    return (
        <>
            <Header/>
            <Box className='section-content' margin="0 auto" fontSize={{xs:16, md:16}} pt={15} pb={15} pl={3} pr={3}>

                <Box component="h1" fontSize={30} textAlign="center" fontWeight="bold" color="#007bff" mb={10}>法令遵守が不可欠な助成金の対応のみならず<br/>勤怠管理の最適化と効率的なシフト運用も実現</Box>

                <Box p={{xs:"20px",md:"30px 100px"}} backgroundColor="#efefef" >

                    <Stack flexDirection={{xs:"column-reverse", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>
                        <Box>
                            <Box fontSize={{xs:16, md:18}} fontWeight="bold" >株式会社Free Style</Box>
                            <Box mt={2}>業種：自動車整備業</Box>
                            <Box mt={1}>サービス：ロードサービス</Box>
                            <Box mt={1}>従業員数：4名</Box>
                        </Box>
                        <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/Customer${customerNo}_Logo.png`} alt="Customer_Logo" width="100%" maxWidth={170}></Box>
                    </Stack>

                    <Stack flexDirection={{xs:"column", md:"row"}} justifyContent="space-between" alignItems="center" gap={2} mt={2}>
                        <Box >
                            <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/Customer${customerNo}_Rep.jpg`} alt="Customer_Rep" width="400px" maxWidth={{xs:"100%",md:"400px"}}></Box>
                        </Box>
                        <Box>
                            <Box>埼玉県北足立郡伊奈町を拠点とするロードサービス会社、株式会社Free Style様。</Box>
                            <Box mt={2}>JAF指定工場として、24時間365日、車の事故や故障などの緊急時にいち早く現場へ駆けつけ、JAF会員を中心に車両の応急処置やレッカー移動などを行っています。お客様からの厚い信頼と高い評価と共に、JAFからもその貢献度が評価され、幾度も表彰されている会社様です。また、全国初のJAF登録箱型トレーラーを所有するなど、独自性のある取り組みを進めています。</Box>
                            <Box mt={2}>今回は、代表取締役の小林様と、勤怠管理や給与計算を担当されている奥様の香織様にお話を伺いました。</Box>
                        </Box>
                        
                    </Stack>
                </Box>

                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">24時間いつでも駆けつけるロードサービスを運営する『株式会社Free Style』</Box>
                <Stack flexDirection={{xs:"column-reverse", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>
                    
                    <Box>
                        <Box fontWeight="bold">――御社は、どのような事業をされているのですか？</Box>
                        <Box mt={2}>JAF（日本自動車連盟）のロードサービスを請け負う指定工場として、主にJAF会員様、時には非会員様向けに救援要請のあった現場へ駆けつけています。車の事故や故障、ガス欠、バッテリー上がりなどで自走できなくなった車両に対し、応急処置やレッカー移動を行っています。</Box>
                        <Box mt={2}>24時間体制で、夜中の要請にも対応できるようスタッフが待機しています。実は昨夜も、新潟から舞浜まで、東京ディズニーランドへ向かう途中で動かなくなった車両を、修理工場までかなりの長距離を夜中にレッカー移動してきました。</Box>

                        <Box mt={5} fontWeight="bold">――大変なお仕事ですね。私の家族もJAF会員なので、何かあった時に緊急対応してもらえる安心感があります。それを支えているのが、御社のような会社やスタッフの皆さんなのですね。</Box>
                    </Box>
                    <Box>
                        <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/Customer${customerNo}_01.jpg`} alt="Customer_01" width="500px" maxWidth={{xs:"100%",md:"500px"}}></Box>
                        <Box fontSize={10} textAlign="center">日本に2台しかない箱型トレーラー。ラッピングはFree Styleさんオリジナルで世界に1台のデザイン。<br/>運送中の車両が傷つかない＆濡れない、箱型トレーラーとしては全国初でJAFに登録。</Box>
                    </Box>
                    
                </Stack>


                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">法令遵守が不可欠な助成金にも対応できるTRIPORTは、<br/>労務のエキスパートとして信頼ができる</Box>
                <Box fontWeight="bold">――どのようなきっかけでTRIPORTとどのように出会ったのでしょうか？</Box>
                <Box mt={2}>法人化した初期に、弊社に出入りしていた保険会社の営業さんからTRIPORTをご紹介いただきました。</Box>
                <Box mt={2}>個人事業主からスタートした事業を拡大し、さらに人手が必要になったタイミングで、2022年2月に法人化しました。採用予定だった方のご家族から「福利厚生がしっかりしていないと不安だ」との声があり、従業員を新たに雇うための準備として法人化を決断したのです。従業員と、そのご家族にも安心してもらえる環境を作ろうと思いました。</Box>
                <Box mt={2}>労務に関しては自分自身の知識が乏しく、プロの相談相手が必要だと感じ、最初はTRIPORTとは別の年配の社労士さんに相談していました。しかし、弊社のような特殊な業態では、運用上の都合も考えながら法令遵守を図ることが難しく、現場の実情と法令遵守をどのように擦り合わせていけばいいのか悩んでいました。ところが、その社労士さんからは「あれはNG、これもNG」と言われるばかりで、どのように解決すればよいのかを教えてもらえず困っていました。</Box>
                <Box mt={2}>そんな時に、保険会社の営業さんからTRIPORTを紹介していただきました。実際に利用している会社が満足していると聞き、TRIPORTから話を聞いてみたいと思ったのがきっかけです。</Box>

                <Box mt={5} fontWeight="bold">――『助成金コーディネート』に申し込んだ決め手は何でしたか？</Box>
                <Box mt={2}>遵法性に対して厳しくチェックされる助成金の申請経験が豊富にあるということは、TRIPORTが労務の専門知識と高い対応力を持つ信頼できる会社だと感じ、それが決め手となりました。</Box>
                <Box mt={2}>また、最初に打ち合わせをした際、担当者の方が非常にハキハキとしていて、第一印象から好感を持ちました。特殊な労働環境である弊社の状況をきちんとヒアリングし、法律に則った解決策を提案してくれたため、「ここなら安心して労務相談ができる」と感じました。この感覚は、今に至るまで変わっていません。</Box>



                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">TRIPORTのサポートで実現した勤怠管理の最適化と効率的なシフト運用</Box>
                <Stack flexDirection={{xs:"column", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>

                    <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/Customer${customerNo}_02.jpg`} alt="Customer_02" width="100%" maxWidth={500}></Box>
                    <Box>
                        <Box fontWeight="bold">――TRIPORTのサービスをご利用いただく中で苦労した点や、「想像していたより大変だった…」と感じたことはありましたか？</Box>
                        <Box mt={2}>勤怠管理、給与計算、残業代の計算…、すべてです（笑）。</Box>
                        <Box mt={2}>独立・創業するまでは、「給与は勝手に振り込まれる」という立場でした。しかし、いざ会社を設立し社員を雇うようになると、勤怠管理も給与計算も残業代の計算も、すべて会社として管理しなければならなくなりました。今まで意識したことのなかった分野について、ゼロから理解し、正確に運用することの難しさを実感しました。</Box>
                        <Box mt={2}>労務に関する知識も運用の経験もない状態からスタートしましたが、TRIPORTの担当者に一つ一つ相談し、教えてもらいながらサポートを受けつつ進めてきました。まだ分からないことも多いですが、少しずつ理解が深まり、対応できることが増えてきています。</Box>
                        
                        <Box mt={5} fontWeight="bold">――TRIPORTのサービスのおかげで解決した会社のお悩みはありましたか？</Box>
                        <Box mt={2}>はい、勤怠管理や給与計算、残業代の計算が正確にできるようになりました。特に、TRIPORTのサポートを受けて初めて、待機時間と稼働時間をしっかり管理することの重要性に気づきました。</Box>
                        <Box mt={2}>24時間体制で対応するロードサービス業務では、従業員が待機している時間と実際に稼働している時間が勤務時間に含まれますが、以前の勤怠管理方法では、これらの時間を正確に把握・評価することができていませんでした。TRIPORTのサポートにより、待機時間と稼働時間を適切に管理できるようになり、シフトの見直しも進みました。その結果、従業員の負担を軽減し、効率的な勤務体制を再構築できたと思います。</Box>
                        <Box mt={2}>これにより、従業員の時間単価は上がりつつ、経営側としては法律に則った適切な賃金を支払うことができるようになりました。従業員と経営者双方にとってメリットのある働き方を実現できたことは、成果の１つだと思います。</Box>
                    </Box>

                </Stack>
                

                <Box mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">自社に最適な助成金を最適なタイミングで提案してくれるから助かる。<br/>中型免許取得にも使える「人材開発支援助成金」</Box>
                <Box fontWeight="bold">――TRIPORTのサポートで「キャリアアップ助成金・正社員化コース」を申請したと思いますが、初めて助成金を申請してみて何か感じたことはありますか？</Box>
                <Box mt={2}>もっと早く知っていればよかった…と思いますね（笑）。法人化して最初の社員を雇うときから知っていれば、もっと助成金をもらえていたでしょう。でも、もし今でもTRIPORTのサポートに出会っていなければ、いつまでも助成金を受け取れなかったかもしれません。</Box>
                <Box mt={2}>助成金自体は調べれば情報は見つかるかもしれませんが、要件を知るだけでも見るべき資料も多く、本業の合間に助成金の対応をすることは本当に難しいと思います。</Box>
                <Box mt={2}>TRIPORTのサポートは、数ある助成金の中から自社に適したものをピックアップし、「こんな助成金がありますよ」「このシチュエーションならこの助成金も使えますよ」と提案してもらえるので、とても楽です。引き続き、他にも利用できる助成金があれば、ぜひ提案してほしいです！</Box>

                <Box mt={5} fontWeight="bold">――ちなみに御社はこの先も社員を増やす予定などはありますか？</Box>
                <Box mt={2}>はい。実は最近JAFから提案があり、弊社に新たな支店を作ろうと考えています。そのためには新たに4～5人のスタッフを採用する必要があります。新人への教育も必要ですので、少しずつにはなりますが、今後人を増やしていきたいと考えています。</Box>

                <Box mt={5} fontWeight="bold">――御社にはどのような方が入社されるのでしょうか？</Box>
                <Box mt={2}>年齢制限はありませんが、今いるスタッフは私より若いメンバーです。昨年入社した19歳のスタッフも頑張っていますよ。入社後、3ヶ月はみっちり同行教育をしています。また、入社時に普通免許しか持っていない方については、入社後に中型免許を取得してもらい、少しずつ業務の幅を広げています。</Box>

                <Box mt={5} fontWeight="bold">――社員の中型免許取得に使える「人材支援開発助成金」というのがあることを、ご存知ですか？</Box>
                <Box mt={2}>そうなんですか？！知らなかったです。</Box>
                
                <Box mt={5} fontWeight="bold">――会社が免許取得のための費用を全額負担する場合、その経費の一部を助成する制度があります。<br/>また、研修の時間は会社の必要な教育訓練であり、労働時間の一部という認識がありますから、その時間にかかる賃金の一部も助成してもらえるんです。</Box>
                <Box mt={2}>そうなんですね。具体的に支店を構える際、新たに社員を雇ったり、免許取得のための研修などを実施する際には、事前に相談させてください！</Box>

                <Box mt={5} fontWeight="bold">――はい。ぜひ、具体的に実施される前にご相談くださいね！*</Box>
                <Box mt={2} fontSize={10}>* 助成金は事前に計画や申請が必要なものも多いため、計画段階から予めご相談いただいています。</Box>


                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">顧問社労士さんがいる会社でもOK。<br/>助成金に興味がある全ての会社にオススメ</Box>
                <Stack flexDirection={{xs:"column-reverse", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>
                    
                    <Box>
                        <Box fontWeight="bold">――TRIPORTのサービスへの満足度を教えてください。</Box>
                        <Box mt={2}>実は私は、直接対面して打ち合わせをしたいタイプで、オンラインでの打ち合わせや相談が少し苦手なんです…。その点を考慮して、10点満点中9点とさせていただきます。</Box>
                        <Box mt={2}>対面でのお打ち合わせができない点を除けば、サービスの内容にはとても満足しています！オンラインだからこそ、会社がどこにあってもTRIPORTのサービスを受けられ、移動時間も節約できて、スムーズに業務が進められていることも理解しています。</Box>

                        <Box mt={5} fontWeight="bold">――最後に、『助成金コーディネート』は、どのような企業様におすすめできると思いますか？</Box>
                        <Box mt={2}>顧問社労士がいる会社でも、いない会社でも、「助成金に興味がある」「助成金の申請はハードルが高いと感じる」「自分の事業に集中したいので、書類作成や申請業務を代行してほしい」と思っている会社には、ぜひオススメしたいですね。</Box>
                        
                    </Box>
                    <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/Customer${customerNo}_03.jpg`} alt="Customer_03" width="100%" maxWidth={500}></Box>

                </Stack>

                <Box mt={5} fontWeight="bold">――小林様、香織様、本日はありがとうございました！<br/>インタビューを通して、社員の皆さんに対してできる限り良い労働環境を整えたいという想いが伝わってきました。<br/>TRIPORTのサポートによって、これまで苦労されていた勤怠管理や給与計算、残業代計算の課題が解決し、助成金も活用できたことを、私たちも非常に嬉しく思います。</Box>
                
                <Stack mt={10} direction={{xs:"column",md:"row"}} alignItems="center">
                    <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/Customer_TRIStuff_02.png`} alt="TRIStuff" width="100%" maxWidth={80}></Box>
                    <Box width="50px" height="25px" sx={{clipPath:{xs:"polygon(50% 0, 0 100%, 100% 100%)",md:"polygon(0 50%, 100% 0, 100% 100%)"},transform:{xs:"translateY(5px)",md:"translateX(10px)"}}} backgroundColor="#D1EAFE"></Box>
                    <Box flex={1} p={2} backgroundColor="#D1EAFE">
                        <Box display="inline-block" p={1} fontWeight="bold" backgroundColor="white" borderRadius={50}>TRIPORTの労務・助成金担当者の声</Box>
                        <Box mt={1}>事業主である小林様も奥様も会社をより良くしよう、従業員のために整備しようというお気持ちが強く、お話をさせていただく度に私も最大限お力になりたいと思います。オンラインであっても、身近に感じていただける存在でいたいです。労務まわり（採用、勤怠管理、雇用関係、給与計算、働き方、待遇面、助成金など）で迷うことがあれば、いつでもお気軽にお声がけくださいね！</Box>
                    </Box>
                    
                </Stack>

                <Stack mt={10} p={2} direction="row" flexWrap="wrap" backgroundColor="#EBEBEB" justifyContent="flex-start" gap={5}>
                    <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/Customer${customerNo}_04.jpg`} alt="Customer_04" width="600px" maxWidth="100%" ></Box>
                    <Box flex={1}>
                        <Stack direction="row" justifyContent="space-between">
                            <Box fontWeight="bold" flex={1} fontSize={18}>株式会社Free Style</Box>
                            <Stack direction="row"  gap={1}>
                                <Link href="https://www.facebook.com/p/%E5%B0%8F%E6%9E%97-%E5%85%83%E6%A8%B9-100083101012654/" target='_blank'><FacebookIcon fontSize='large' sx={{color:'black'}}/></Link>
                                <Link href="https://www.instagram.com/freestyle20201010/" target='_blank'><InstagramIcon  fontSize='large' sx={{color:'black'}}/></Link>
                            </Stack>
                        </Stack>
                        <Stack direction="row" alignItems="center"><PhoneIcon fontSize='small'/><Link href="tel:048-778-7297">048-778-7297</Link></Stack>
                        <Box mt={2} ml={1}>
                            <Box>埼玉県北足立郡伊奈町を拠点とするロードサービス会社（JAF指定工場）。</Box>
                            <Box>エンジンがかからない、パンク、ガス欠、インロックなど自動車・バイクのトラブルに、24時間365日営業で対応。JAFで対応外のトラブルも相談可。</Box>
                            <Box>お困りの際はまずお電話を。</Box>
                        </Box>
                    </Box>
                </Stack>
            </Box>

            <CustomersFooter/>
            <Footer/>
        </>
    );
}

export default Customer5;