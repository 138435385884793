import React from 'react';
import {Box,Stack,Link} from '@mui/material'
import ConversionButtonA from "../../Components/ConversionButtonA";
import ConversionButtonB from "../../Components/ConversionButtonB";
import Header from '../LP/Header';
import Footer from '../LP/Footer';
import AccentBox from '../../Components/AccentBox';
import { useSearchParams } from "react-router-dom";

function Customers(){

    const [searchParams] = useSearchParams();

    let queryString = searchParams.toString()
    if(queryString?.length > 0){
        queryString = `?${queryString}`;
    }

    const styledLink=(url)=>{
        return(<Link href={url} target="_blank" backgroundColor="#4771DF" borderColor="#4771DF" border={3} sx={{
            color:"white",
            textDecoration:"none",
            "&:hover":{
                backgroundColor:"white",
                color:"#4771DF"
            }
            }} fontWeight="bold" textAlign="center" mt={2} p={2} width={200} borderRadius={10}>
            <Box position="relative" sx={{
                "&::after":{
                    content:'"〉"',
                    position:"absolute",
                    right:"0",
                }
            }}>詳しく見る</Box>
        </Link>)
    }

    const customersList =[
        {
            id:"kamiyasou",
            accountName:"神谷荘",
            imageName:"TOP_casestudy4",
            industry:"宿泊業・飲食業",
            employee:"7名",
            title:<>人手不足が解消！<br></br>設備購入費の90%が助成金で賄えた</>,
            detail:"「業務改善助成金」を活用し、90万円の食洗機の購入費の約90% 80万円が助成金として支給されました。慢性的な人手不足が解消され、経営側も従業員側も喜んでいます。知識・ナレッジが豊富なTRIPORTのサポートのおかげで、ハードルが高いと思っていた助成金を身近に感じると共に、助成金活用で経営課題が解消できました。"
        },
        {
            id:"freestyle",
            accountName:"株式会社Free Style",
            imageName:"TOP_casestudy5",
            industry:"自動車整備業",
            employee:"4名",
            title:<>助成金のみならず<br></br>勤怠管理やシフト運用も最適化</>,
            detail:"労務まわりに悩みを抱えていましたが、TRIPORTのサポートで勤怠管理とシフト運用の最適化を実現。無駄のないシフト運用を実現し、経営者と従業員双方にメリットのある環境を整えることができました。助成金申請前に労務まわりをしっかり整備できたことで、「キャリアアップ助成金・正社員化コース」の申請もスムーズに進みました。"
        },
        {
            id:"aptamuse",
            accountName:"株式会社アプトアミューズ",
            imageName:"TOP_casestudy6",
            industry:"介護業",
            employee:"10名",
            title:<>スムーズに助成金を受給。<br></br>労務の専門家として頼れる存在</>,
            detail:"TRIPORTの助成金サポートを活用し、労務の整備をしながら、「キャリアアップ助成金」「両立支援等助成金」などの申請をスムーズに進められました。担当者の丁寧な対応と豊富な知識に支えられ、安心して労務相談から申請までを任せられる点が大きな魅力です。経営者として抱えていた労務面での不安が大きく軽減されました。"
        },
        {
            id:"nicoichi",
            accountName:"株式会社にこいち",
            imageName:"TOP_casestudy1",
            industry:"広告業",
            employee:"7名",
            title:<>従業員にとって<br></br>働きやすい環境がつくれた</>,
            detail:"助成金コーディネートを導入して従業員の働きやすい環境づくりができ、会社の利益にもなりました。まずは従業員が働きやすい環境をしっかりとつくり、その結果、助成金が受給できるというサービスで本当の意味で会社の成長を応援してくれるサービスです。"
        },
        {
            id:"criacao",
            accountName:"株式会社Criacao",
            imageName:"TOP_casestudy2",
            industry:"サービス業",
            employee:"31名",
            title:<>安心して採用ができ<br></br>従業員が2倍に</>,
            detail:"自社の思い描く労働環境を実現するための整備がしっかりできました。労働基準法を遵守する形で就業規則、雇用契約書、制度導入のための協定書、労使協定を帳簿などに落とし込みができ、採用担当が採用に専念できる環境と働く社員とそのご家族まで安心できる環境が整いました。"
        },
        {
            id:"overcome-music",
            accountName:"株式会社OVERCOME MUSIC",
            imageName:"TOP_casestudy3",
            industry:"情報通信業",
            employee:"3名",
            title:<>社長が何役も兼任している企業に<br></br>オススメ</>,
            detail:"小規模の会社や個人事業主から法人成りしたばかりの会社さんには経営者が本業に集中できることが、会社の業績を上げるために重要です。助成金コーディネートは手間が少なく、自社の労務周りの整備から助成金受給までしっかりと行われるかつ経営リスク回避、社員に安心して働いてもらえる環境まで整えられます。"
        },
        
    ]

    return (
        <>
            <Header/>

            <Box component="section" backgroundColor="#D1EAFE" pt={30} pb={20}>
                <Box className='section-content' >
                    <Box component="h1" textAlign="center" fontSize={{xs:20, md:30}} fontWeight="bold">お客様の声</Box>
                </Box>
            </Box>

            <Box component="section" backgroundColor="white" pt={10} pb={10}>
                <Box className='section-content' textAlign="center">
                    
                    <Stack flexDirection={{sx:"column",md:"row"}} flexWrap="wrap" justifyContent="center" gap={3}>

                        {customersList.map((item,index) => (
                            <Link display="flex" flex={1} color="inherit" flexBasis="30%" href={`/customers/${item.id}${queryString}`} sx={{textDecoration:"none"}}>
                                <Stack  p={2} boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px" borderRadius={5} >                
                                    <Box>
                                        <Box backgroundColor="#4771DF" color="white" fontSize={16} fontWeight="bold" p={1} textAlign="left">{item.accountName}</Box>
                                        <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/${item.imageName}.jpg`} alt={item.imageName} width="100%"></Box>
                                    </Box>
                                    <Box mt={1} >
                                        <Stack component="h3" flexDirection="row" fontSize={11} gap={1}>
                                            <Box width={70} textAlign="center" backgroundColor="#D1EAFE" fontWeight="bold">業　種</Box>
                                            <Box>{item.industry}</Box>
                                        </Stack>
                                        <Stack flexDirection="row" fontSize={11} mt={1} gap={1}>
                                            <Box width={70} textAlign="center" backgroundColor="#D1EAFE" fontWeight="bold">従業員数</Box>
                                            <Box>{item.employee}</Box>
                                        </Stack>
                                    </Box>
                                    <Box fontSize={22} fontWeight="bold" borderTop="1px dotted gray" mt={1} pt={1} textAlign="left">{item.title}</Box>
                                    <Box flex={1} fontSize={14} borderTop="1px dotted gray" mt={1} pt={1} textAlign="left">{item.detail}</Box>
                                    <Stack alignItems="center">{styledLink(`/customers/${item.id}${queryString}`)}</Stack>
                                </Stack>
                            </Link>
                            )
                        )}
                        
                    </Stack>
                    
                </Box>
            </Box>

            <Box component="section" backgroundColor="#D1EAFE" pt={10} pb={10}>
                <Box className='section-content' textAlign="center">
                    
                    <Stack fontSize={30} flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" alignItems="center" gap={3}>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_award1.png"} alt="TOP_award1" width="100%" maxWidth={250}></Box>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_award2.png"} alt="TOP_award2" width="100%" maxWidth={250}></Box>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_award3.png"} alt="TOP_award3" width="100%" maxWidth={250}></Box>
                    </Stack>
                    <Box fontSize={11} mt={6} textAlign="left">
                        <Box>※1 2024年3月現在</Box>
                        <Box>※2 日本HRチャレンジ大賞は、助成金コーディネートの元となるサービスである「クラウド社労士コモン」が受賞しました。</Box>
                        <Box>※3 厚生労働省『輝くテレワーク賞』特別奨励賞に受賞、総務省『テレワーク先駆者百選』に選出、東京都『東京ライフワークバランス認定企業』に認定、東京都『東京都女性活躍推進大賞』優秀賞を受賞、など</Box>
                    </Box>

                    <Box component="h1" fontWeight="bold" fontSize={{xs:30, md:40}} mt={10}>
                        <Box>助成金<Box component="span" fontSize={{xs:20, md:25}} ml={1} mr={1}>を</Box>フル活用<Box component="span" fontSize={{xs:20, md:25}} ml={1} mr={1}>して</Box></Box>
                        <Box>社員が輝く経営サイクル<Box component="span" fontSize={{xs:20, md:25}} ml={1}>をつくる。</Box></Box>
                    </Box>

                    <Box fontWeight="bold" fontSize={{xs:35, md:65}} mt={5} mb={5}>「助成金コーディネート」</Box>

                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_J_coordinate.png"} alt="TOP_J_coordinate" width="100%" maxWidth={500}></Box>

                    <Box fontSize={{xs:20, md:30}} fontWeight="bold" mt={10}>
                        <Box><AccentBox>多数実績</AccentBox><Box component="span" fontSize={{xs:16, md:20}} ml={1} mr={1}>の中で培ってきた</Box><Box component="br" display={{xs:"block",md:"none"}}></Box><AccentBox>ノウハウ・ナレッジ。</AccentBox></Box>
                        <Box><Box component="span" fontSize={{xs:16, md:20}} ml={1} mr={1}>労務・助成金の</Box><AccentBox>プロ集団</AccentBox><Box component="span" fontSize={{xs:16, md:20}} ml={1} mr={1}>が、継続的に助成金をフル活用できる</Box><AccentBox>しくみづくり</AccentBox><Box component="span" fontSize={{xs:16, md:20}} ml={1} >をサポートします。</Box></Box>
                    </Box>
                    
                </Box>
            </Box>

            <Box component="section" backgroundColor="#4771DF" textAlign="center" pt={10} pb={10}>
                <Box className='section-content' textAlign="center" color="white">
                    <Box fontSize={{xs:20, md:30}}><Box component="span" fontSize={{xs:30, md:45}} mr={1}>助成金、</Box>どうせやるなら<Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>「フル活用」</Box>！</Box>
                    
                    <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" mt={10} gap={5}>
                        
                        <Stack flex={1} alignItems="center" gap={2}>
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/CTA1_2.png"} alt="CTA1_2" width="100%" maxWidth={400}></Box>

                            <ConversionButtonA display="block" fontSize={{xs:20, md:30}} >無料<Box component="span" margin="0 4px" fontSize={{xs:16, md:20}}>で</Box>助成金診断<Box component="span" margin="0 4px" fontSize={{xs:16, md:20}}>する</Box></ConversionButtonA>
                        </Stack>
                        
                        <Stack flex={1} alignItems="center" gap={2}>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/CTA2_2.png"} alt="CTA2_2" width="100%" maxWidth={400}></Box>
                            <ConversionButtonB display="block" fontSize={{xs:20, md:30}} >無料<Box component="span" ml={1} mr={1} fontSize={{xs:16, md:20}}>で</Box>プロに相談<Box component="span" ml={1} fontSize={{xs:16, md:20}}>する</Box></ConversionButtonB>
                        </Stack>
                        
                    </Stack>
                </Box>
            </Box>

            <Footer/>
        </>
    )
}

export default Customers;